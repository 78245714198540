import axios from "axios";

export const getAllCountries = async () => {
  try {
    const countriesKey = "allCountriesCache"; // LocalStorage key for countries

    // Check if the countries data is in localStorage
    const storedData = localStorage.getItem(countriesKey);

    if (storedData) {
      // Use data from localStorage if available
      const parsedData = JSON.parse(storedData);
      console.log("Using countries data from localStorage", parsedData);
      return parsedData;
    } else {
      // If not found in localStorage, make the API call
      const response = await axios.get("/api/region/countries");
      const countriesData = response?.data;

      if (countriesData) {
        // Save the API response in localStorage
        localStorage.setItem(countriesKey, JSON.stringify(countriesData));

        // Return the fetched countries data
        return countriesData;
      }
    }
  } catch (error) {
    console.error("Error fetching countries data:", error);
    throw error;
  }
};

export const getStates = async (data) => {
  const res = await axios
    .post("/api/region/states", data)
    .then((response) => response?.data);
  return res;
};

export const getCities = async (data) => {
  const res = await axios
    .post("/api/region/cities", data)
    .then((response) => response?.data);
  return res;
};

export const getAllTimeZones = async () => {
  return await axios
    .get("/api/region/timeZone")
    .then((response) => response?.data);
};

// export const getStateByZipCode=()=>{

// }
