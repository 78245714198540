import { useNavigate } from "react-router-dom";
import "./Vetting.scss";

export const Vetting = ({ data }) => {
  const navigate = useNavigate();

  const handleRedirect = (link) => {
    navigate(link);
  };
  return (
    <div className="vetting">
      <div className="wrapper">
        <div className="left-side">
          <div>{data?.heading}</div>
          <div>{data?.title}</div>
          <div>{data?.desc}</div>
          <div className="cta-wrapper">
            <div className="main-button">
              {data?.button && (
                <button onClick={() => handleRedirect(data?.button?.link)}>
                  {data?.button?.title}
                </button>
              )}
            </div>
            {data?.cta && (
              <div className="main-cta">
                <div>{data?.cta?.title}</div>
                <div>
                  <img src={"./Play.svg"} alt="" />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="right-side">
          <div className="no-and-lines">
            {data?.rightData?.map((ele, index) => (
              <div className="each-item">
                <div>{index + 1}</div>
                {index < data?.rightData.length - 1 && <div></div>}
              </div>
            ))}
          </div>
          <div className="main-data">
            {data?.rightData?.map((ele, index) => (
              <div className="each-data" key={index}>
                <div>{ele?.title}</div>
                <div>{ele?.desc}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
