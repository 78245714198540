import React, { useState,useEffect,useRef} from 'react';
import { Box, IconButton, Paper, Typography, TextField, Button, Stack ,InputAdornment,Grid} from '@mui/material';
import ChartIcon from  "../../../Assets/client_tour/ChatBotIcon.svg"
import CloseIcon from '@mui/icons-material/Close';
import { intialSuggestions } from './ChatBotData';
import SendIcon from '@mui/icons-material/Send'; 
import { chatBotMapSkillsFromMessage,chatBotMapSkillsFromMessageNew } from '../../../Common/Client';
import { useNavigate } from 'react-router-dom';


const ChatBot = ({openChatBot,handleGetCandidateListFromBot}) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate=useNavigate();
  const [messageStack,setMessageStack]=useState([]);
  const [isTyping,setIsTyping]=useState(false);
  const [typedText, setTypedText] = useState('');
  const [session,setSession]=useState(true);
  const [hireCandidatesClicked,setHireCandidatesClicked]=useState(false)

  

  const handleSendClick = async() => {
    setMessageStack((prev)=>[...prev,{message:typedText,owner:"client"}])
    setIsTyping(true)
    setTypedText(''); // Clear the text field after sending
    if (typedText.trim()) {
        const obj={
            new_session:session,
            message:typedText.trim(),
            client_id:localStorage.getItem("email")
        }
        const res=await chatBotMapSkillsFromMessageNew(obj);
        setSession((prev)=>{
            return false;
        })
        if(res?.api_message){
          
            setMessageStack((prev)=>[...prev,{message:res?.message,owner:"bot"}])
            setTimeout(()=>{
             
              setIsOpen(false);
              navigate("/client/searchtalent",{state:{api_message:res?.api_message}})
            },[1000])
           
        }else{
            setMessageStack((prev)=>[...prev,{message:res?.message,owner:"bot"}])
        }
      setTypedText(''); // Clear the text field after sending
      setIsTyping(false)
    } else {
     
    }
  };

  // Function to handle input changes
  const handleInputChange = (event) => {
    setTypedText(event.target.value); // Update the state with the new input value
  };
  

  // Toggle the chatbot window
  const toggleChatWindow = () => {
    setIsOpen(!isOpen);
  };

  const handleSuggestionClicked=(index)=>{
    if(index===0){
        setMessageStack((prev)=>[...prev,{message:'I want to hire',owner:'client'}])
        setIsTyping(true)
        setMessageStack((prev)=>[...prev,{message:'What type of candidate do you require?',owner:'bot'}])
        setIsTyping(false)
        setHireCandidatesClicked(true)


    }
  }

  const handleResetClick=()=>{
    setMessageStack([])
    setHireCandidatesClicked(false);
    setSession(true);
  }

  const messagesEndRef = useRef(null);

  // Scroll to bottom when a new message is added
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messageStack, isTyping]); // Run this effect when messages or typing status change

  return (
    <>
      {/* Chatbot Icon */}
      <Box
       sx={{
        position: 'fixed',
        bottom: 50,
        right: 36,
        zIndex: 1000,
        height: '60px',
        width: '60px',
        borderRadius: '60px',
        border: '1px solid #0B51D1',
        backgroundColor: '#FFF',
        boxShadow: '-2px -2px 4px rgba(33, 33, 33, 0.16), 2px 2px 4px rgba(33, 33, 33, 0.16)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      >
        <IconButton
  onClick={toggleChatWindow}
  sx={{
    backgroundColor: '#fff',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
    },
    padding: '8px', // Adjust padding if needed for proper image display
  }}
  size="large"
>
  <img 
    src={ChartIcon}
    alt="Chat Icon"
    style={{ width: '42px', height: '42px' }} // Adjust size if necessary
  />
</IconButton>

      </Box>

      {/* Chat Window */}
      {isOpen && (
        <Paper
          sx={{
            position: 'fixed',
            bottom: 180, // Adjust this to move it higher or lower
            right: 56,
            width: 400,
            height: 500,
            zIndex: 999,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            backgroundColor: '#fff',
            boxShadow: '0px 3px 10px rgba(0,0,0,0.2)',
            borderRadius: '12px',
          }}
          elevation={3}
        >
          {/* Header with Close Icon */}
          <Box
  sx={{
    padding: '10px 20px',  // Added more padding for better layout
    background: 'linear-gradient(90deg, rgba(6, 43, 110, 1) 0%, rgba(11, 82, 212, 1) 100%)',
    color: '#fff',
    borderTopLeftRadius: '12px',
    borderTopRightRadius: '12px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }}
>
  {/* Image on the left */}
  <Box sx={{ display: 'flex', alignItems: 'center',gap:'14px' }}>
  <Box
      sx={{
        width: '44px',
        height: '44px',
        borderRadius: '50px',
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center', // Center the image inside the box
      }}
    >
      <img 
        src={ChartIcon} 
        alt="Assistant Logo" 
        style={{ width: '40px', height: '40px', borderRadius: '50%' }}  // Centered and rounded image
      />
    </Box>
    <Typography
  variant="h6"
  sx={{
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '24px',
    textAlign: 'center',
  }}
>
  Zen: Your AI Assistant
</Typography>

  </Box>

  {/* Close icon on the right */}
  <IconButton onClick={toggleChatWindow} sx={{ color: '#fff' }}>
    <CloseIcon />
  </IconButton>
</Box>


          {/* Chat Messages Area */}
          <Box
            sx={{
              flex: 1,
              padding: '10px',
              overflowY: 'auto',
            
            }}
          >
            {/* Messages go here */}
            <Typography
  variant="body2"
  sx={{
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '21px',
    textAlign: 'left',
    color: 'textSecondary', // Keeps the original color
    marginBottom:'16px',
  }}
>
  Hi! How can I assist you today?
</Typography>
<Stack>

    {intialSuggestions.map((suggestion,index)=>(
        <Box
        onClick={(e)=>{
            handleSuggestionClicked(index)
        }}
          sx={{
            width: '147px',
            height: '38px',
            display: 'flex',
            justifyContent: 'center',
            margin:'0px 16px 16px 0px',
            alignItems: 'center',
            borderRadius: '8px',
            border: '1px solid rgba(52, 168, 83, 1)', // Green border
            opacity: 1, // Set to 1 for visibility, adjust if needed
            gap: '0px',
            cursor:'pointer'
          }}
        >
          <Typography
            variant="body2"
            sx={{
              fontFamily: 'Poppins',
              fontSize: '14px',
              fontWeight: '500',
              lineHeight: '21px',
              textAlign: 'center', // Center align the text
              color: 'rgba(52, 168, 83, 1)', // Green text color
            }}
          >
            {suggestion?.message}
          </Typography>
        </Box>
    ))}
</Stack>
{
    messageStack?.map((message,index)=>(
        message.owner==="client"?<TextOfClient text={message?.message|| ""}/>:<TextOfBot  text={message?.message|| ""}/>
    ))
}
{isTyping && <TypingEffect />}
<div ref={messagesEndRef} />




          </Box>
        



          {/* Chat Input Field */}
          <Box
  component="form"
  sx={{
    display: 'flex',
    flexDirection: 'column', // Align children vertically
    alignItems: 'flex-start', // Align to the start (left)
    justifyContent: 'center',
    padding: '20px',
  }}
  onSubmit={(e) => e.preventDefault()} // Prevent form submission (refresh)
>
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'flex-start', // Align the button to the start (left)
      cursor: hireCandidatesClicked ? 'pointer' : 'not-allowed', // Disable cursor when not clickable
      color: hireCandidatesClicked ? 'black' : 'gray', // Show different color when disabled
      marginBottom: '8px', // Add space between the button and the text field
      pointerEvents: hireCandidatesClicked ? 'auto' : 'none', // Disable interaction when not clickable
    }}
    onClick={hireCandidatesClicked ? handleResetClick : undefined} // Disable onClick if not clickable
  >
    <Button
      variant="outlined" // Use outlined variant for the button
      sx={{
        padding: '8px 16px', // Apply padding inside the button
        borderRadius: '8px',
        border: hireCandidatesClicked ? '1px solid #0B52D4' : '1px solid gray', // Adjust border when disabled
        color: hireCandidatesClicked ? '#0B52D4' : 'gray', // Adjust text color when disabled
        fontSize: '12px',
        height: '34px',
      }}
      disabled={!hireCandidatesClicked} // Disables button interaction
    >
      Start Again
    </Button>
  </Box>

  <TextField
    value={typedText}
    onChange={handleInputChange} // Update text as the user types
    variant="outlined"
    disabled={!hireCandidatesClicked} // Disable text field when hireCandidatesClicked is false
    onKeyDown={(e) => {
      if (e.key === 'Enter' && hireCandidatesClicked) {
        handleSendClick(); // Call handleSendClick when Enter is pressed if clickable
      }
    }}
    sx={{
      width: '360px', // Set the width for consistency
      height: '44px',
      opacity: hireCandidatesClicked ? 1 : 0.6, // Change opacity if disabled
      pointerEvents: hireCandidatesClicked ? 'auto' : 'none', // Disable interaction when not clickable
      '& .MuiOutlinedInput-root': {
        borderRadius: '22px',
        '& fieldset': {
          border: hireCandidatesClicked
            ? '1px solid rgba(11, 82, 212, 1)' // Default border color
            : '1px solid gray', // Disabled border color
          borderRadius: '22px',
        },
        '&:hover fieldset': {
          border: hireCandidatesClicked
            ? '1px solid rgba(11, 82, 212, 1)' // Simplified hover effect
            : '1px solid gray', // Disabled hover effect
          borderRadius: '22px',
        },
        '&.Mui-focused fieldset': {
          border: hireCandidatesClicked
            ? '1px solid rgba(11, 82, 212, 1)' // Focus effect with solid color
            : '1px solid gray', // Disabled focus effect
          borderRadius: '22px',
        },
      },
      input: {
        color: 'black', // Make the text color black
        padding: '10px',
        backgroundColor: 'transparent', // Keep background transparent
      },
    }}
    InputProps={{
      endAdornment: (
        <InputAdornment position="end">
          <SendIcon
            onClick={hireCandidatesClicked ? handleSendClick : undefined} // Call handleSendClick when the icon is clicked and clickable
            sx={{
              color: hireCandidatesClicked ? 'rgba(10, 78, 200, 1)' : 'gray', // Adjust icon color when disabled
              cursor: hireCandidatesClicked ? 'pointer' : 'not-allowed', // Adjust cursor when disabled
              '&:hover': {
                color: hireCandidatesClicked ? 'rgba(7, 54, 138, 1)' : 'gray', // Change color on hover if enabled
              },
            }}
          />
        </InputAdornment>
      ),
      style: {
        borderRadius: '22px', // Ensure rounded borders on the input
        height: '44px', // Set height for consistency
      },
    }}
  />
</Box>





        </Paper>
      )}
    </>
  );
};

const TextOfBot=({text})=>{
    return (
        <Box
  sx={{
   
    // display: 'inline-flex', 
    // gap: '0px',
    // display: 'flex',
    // alignItems: 'center', // Corrected 'alignItems'
    // justifyContent: 'center',
    // borderTopLeftRadius: '8px',    // Top-left corner
    // borderTopRightRadius: '8px',   // Top-right corner
    // borderBottomLeftRadius: '0px', // Bottom-left corner
    // borderBottomRightRadius: '8px',// Bottom-right corner
    // borderRadius: '8px',
    // background: 'rgba(230, 230, 230, 1)',
    // overflow: 'hidden',
    // padding: '8px',
   
    // margin:'0px 16px 16px 0px',
    display: 'inline-flex', // Ensures the box takes only the width of the content
    gap: '0px',
    padding: '8px',
    borderTopLeftRadius: '8px',    // Top-left corner
    borderTopRightRadius: '8px',   // Top-right corner
    borderBottomLeftRadius: '0px', // Bottom-left corner
    borderBottomRightRadius: '8px',// Bottom-right corner
    background: 'rgba(230, 230, 230, 1)',
    overflow: 'hidden',
    margin: '0px 16px 16px 0px',
    marginRight: 'auto', // This will push the box to the right side
  }}
>
  <Typography
    variant="body2"
    sx={{
      display: 'inline-flex',
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '21px',
      textAlign: 'left',
      color: ' rgba(51, 51, 51, 1)',
    
    }}
  >
   {text}
  </Typography>
        </Box>
    )
}

const TextOfClient=({text})=>{
    return (
      <Grid sx={{display:'flex',alignItems:'end'}}>
<Box
  sx={{
    display: 'inline-flex', // Ensures the box takes only the width of the content
    gap: '0px',
    padding: '8px',
    borderTopLeftRadius: '8px',    // Top-left corner
    borderTopRightRadius: '8px',   // Top-right corner
    borderBottomLeftRadius: '8px', // Bottom-left corner
    borderBottomRightRadius: '0px',// Bottom-right corner
    background: 'linear-gradient(90deg, rgba(6, 43, 110, 1) 0%, rgba(11, 82, 212, 1) 100%)',
    overflow: 'hidden',
    margin: '0px 16px 16px 0px',
    marginLeft: 'auto', // This will push the box to the right side
  }}
>
  <Typography
    variant="body2"
    sx={{
      fontFamily: 'Poppins',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '21px',
      textAlign: '',
      color: '#fff', // White color for better contrast against the gradient
    }}
  >
    {text}
  </Typography>
</Box>
</Grid>


    )
}

const TypingEffect = () => {
    const [dotCount, setDotCount] = useState(0); // Manage the number of visible dots dynamically
  
    useEffect(() => {
      // Create a timer to update the dots (up to 3 dots)
      const interval = setInterval(() => {
        setDotCount((prevCount) => (prevCount === 3 ? 0 : prevCount + 1));
      }, 500); // Change dot every 500ms
  
      return () => clearInterval(interval); // Cleanup the interval on component unmount
    }, []);
  
    return (
      <Box
        sx={{
          width: '186px',
          gap: '0px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '8px',
          borderRadius: '8px',
          background: 'rgba(230, 230, 230, 1)',
          overflow: 'hidden',
          margin: '0px 16px 16px 0px',
        }}
      >
        <Typography
          variant="body2"
          sx={{
            fontFamily: 'Poppins',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '21px',
            textAlign: 'right',
            color: 'rgba(51, 51, 51, 1)',
          }}
        >
          Zen is typing
        </Typography>
  
        {/* Display dots with specific height and width */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginLeft: '8px',
          }}
        >
          {Array.from({ length: dotCount }).map((_, index) => (
            <Box
              key={index}
              sx={{
                width: '6px',
                height: '6px',
                backgroundColor: 'rgba(51, 51, 51, 1)',
                borderRadius: '3px',
                marginLeft: index > 0 ? '5px' : '0px', // Add spacing between dots
              }}
            />
          ))}
        </Box>
      </Box>
    );
  };

export default ChatBot;