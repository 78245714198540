import React from 'react';
import { Box, Card, Grid, Stack, Skeleton, Typography } from '@mui/material';
import './CandidateCardSkeleton.scss'; // CSS file for wind flow animation

const CandidateCardSkeleton = () => {
  return (
    <Card
      sx={{
        background: 'rgba(255, 255, 255, 1)',
        width: '312px',
        height: '243px',
        gap: '0px',
        borderRadius: '8px',
        padding: '5px',
        boxShadow:
          '2px 2px 4px rgba(0, 0, 0, 0.25), -2px -2px 4px rgba(0, 0, 0, 0.25)',
      }}
    >
      {/* Section 1 (Availability Tabs Skeleton) */}
      <Grid sx={{ display: 'flex', gap: '6px', height: '24px' }}>
        <Skeleton variant="rectangular" width={141} height={24} />
        <Skeleton variant="rectangular" width={141} height={24} />
      </Grid>

      {/* Section 2 (Image and Data Skeleton) */}
      <Grid sx={{ display: 'flex', marginTop: '14px', gap: '2px', height: '143px' }}>
        {/* Profile Image Skeleton */}
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Skeleton variant="circular" width={102} height={102} />
          <Skeleton variant="circular" width={36} height={36} sx={{ marginTop: '-20px' }} />
        </Box>

        {/* Data Section Skeleton */}
        <Box sx={{ flex: 1, paddingLeft: '8px' }}>
          {/* Backend Developer Title Skeleton */}
          <Skeleton width="60%" height={19.5} />
          {/* Name Skeleton */}
          <Skeleton width="40%" height={21} sx={{ marginTop: '8px' }} />
          {/* Details Skeleton */}
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Skeleton width="80%" height={19.5} />
            </Grid>
            <Grid item>
              <Skeleton width="60%" height={19.5} />
            </Grid>
            <Grid item>
              <Skeleton width="90%" height={19.5} />
            </Grid>
          </Grid>
        </Box>
      </Grid>

      {/* Section 3 (Custom Chips Skeleton) */}
      <Grid sx={{ display: 'flex', gap: '8px', marginTop: '16px' }}>
        <Skeleton variant="rectangular" width={76} height={30} className="wind-flow" />
        <Skeleton variant="rectangular" width={76} height={30} className="wind-flow" />
        <Skeleton variant="rectangular" width={76} height={30} className="wind-flow" />
        <Skeleton variant="rectangular" width={76} height={30} className="wind-flow" />
      </Grid>
    </Card>
  );
};

export default CandidateCardSkeleton;
