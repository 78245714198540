import "./Blogs.scss";
import doubleArrow from "../../../Assets/home/doubleArrowRight.svg";
import { Typography } from "@mui/material";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
export const BlogsMain = ({ ComponentData }) => {
  return (
    <div className="blogs-main">
      <div className="title">{ComponentData?.title}</div>
      <div className="blog-cards-container">
        {ComponentData?.data?.map((item, index) => (
          <BlogCard key={index} data={item} />
        ))}
      </div>
    </div>
  );
};

const BlogCard = ({ data }) => {
  const { image, title, description, date, readTime, link } = data;

  return (
    <div className="blog-card">
      <div className="blog-card-image">
        <img src={image} alt={title} />
      </div>
      <div className="blog-card-content">
      <Typography
  sx={{
    color: '#FFF', // White text color
    fontFamily: 'Poppins', // Font family
    fontSize: '16px', // Font size
    fontStyle: 'normal', // Font style
    fontWeight: 600, // Font weight
  marginBottom:'8px',
  }}
>
  {title}
</Typography>

        <p className="description">{description}</p>
        <div className="blog-card-footer">
          <div className="date-read-time">
            <div className="date">{date}</div>
            <div className="dot"></div>
            <div className="read-time">{readTime}</div>
            
          </div>

          
         
            
            
            
          
          <div style={{display:'flex',alignItems:'center'}}>
          <a href={link} className="read-more" target="_blank" style={{ display: 'flex', alignItems: 'center',marginLeft:'10px' }}>
  <Typography
    sx={{
      fontSize: { lg: '16px', sm: '14px', xs: '14px' }, // Responsive font size
      color: '#FFF', // Text color
      fontFamily: 'Poppins', // Font family
      fontStyle: 'normal', // Font style
      fontWeight: 500, // Font weight
      lineHeight: 'normal' // Line height
    }}
  >
    Read more
  </Typography>
  <KeyboardDoubleArrowRightIcon sx={{ height: '20px', width: '20px', flexShrink: 0, marginLeft: '0px' }} />
</a>

          </div>
        </div>
      </div>
    </div>
  );
};
