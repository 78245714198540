import { useEffect } from "react";
import { Faqs } from "../../Components/Common/Faqs/Faqs";
import { Layout } from "../../Components/Common/Layout/Layout";
import { RightImage } from "../../Components/Common/RightImage/RightImage";
import { Vetting } from "../../Components/Vetting/Vetting";
import { WeOfferPage3 } from "../../Components/weOfferpage3/weOfferPage3";
import { home2Data } from "../../mockData/Home2";
import "./Home_Client.scss";
import { SeoTags } from "../../Components/Common/SeoTags/SeoTags";

const seoData = {
  title: "Build Top Tech Teams Fast | Streamlined Hiring with Zeero  ",
  desc: "Access pre-vetted developers & scale your software development team with ease. Start building, managing, & scaling your team today! Zeero offers flexible solutions.",
  canonical: "client",
};
export const Home_Client = () => {
  useEffect(() => {
    const ele = document.getElementById("main-d");
    ele.scrollIntoView();
  }, []);
  return (
    <>
      <SeoTags seoData={seoData} />
      <div id="main-d">
        <Layout>
          <div className="main-item-client">
            <RightImage ComponentData={home2Data?.first} background={"#fff"} />
          </div>
          <WeOfferPage3 data={home2Data?.weoffer} />
          <Vetting data={home2Data?.howToHire2} />
          <Faqs faqData={home2Data?.faqs} />
        </Layout>
      </div>
    </>
  );
};
