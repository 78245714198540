import { CandidateDetailsHeader } from "../../../Components/CandidateDetailsHeader/CandidateDetailsHeader";
import { CandidateHeader } from "../../../Components/CandidateHeader/CandidateHeader";
import "./ProfessionalDetails.scss";
import { SkillsData } from "../../../mockData/Skillpage";
import { useEffect, useRef, useState } from "react";
import arrowDown from "../../../Assets/SVG/dropDownArrow.svg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
import { FrontEndField } from "./Frontend";
import { BackendField } from "./Backend";
import { FullStackField } from "./FullStack";
import { GlobalLoader } from "../../../Components/Common/GlobalLoader/GlobalLoader";
import { getUserProgress } from "../../../Common/flowAfterLogin";
import { DataScience } from "./DataScience";

const skillFormDataInit = {
  id: "",
  skills: {
    field: "",
    subfield: "",
    skillsdev: [],
  },
};

export const ProfessionalDetails = () => {
  const navigate = useNavigate();
  const checkForDetails = async () => {
    try {
      const res = await getUserProgress();
      const data = res?.progress;
      if (data.includes("Personal Details")) {
        // It has both "Personal Details" and "Professional Experience & Technical Skills"
        // window.location.href = "/assessment/home";
        return;
      } else {
        // It doesn't have either "Personal Details" or "Professional Experience & Technical Skills"
        navigate("/details/personal");
      }
    } catch (err) {
      console.log("checkForDetails error==>", err);
    }
  };

  useEffect(() => {
    checkForDetails();
  }, []);
  return (
    <>
      <CandidateHeader />
      <CandidateDetailsHeader indexActive={1} />
      <div className="Professional-details">
        <div className="title">
          Please complete your profile. This will help us to customize the tests
          in order to identify your strongest skills and match you with the
          suitable projects.
        </div>
        <DevProfessionalDetails />
      </div>
    </>
  );
};

const DevProfessionalDetails = () => {
  const navigate = useNavigate();
  // data for page and options
  const [skillPageData, setSkillPageData] = useState([...SkillsData]);
  // form data for fields and skills to send to the server
  const [skillsForm, setSkillsForm] = useState({ ...skillFormDataInit });
  // field options
  const [fieldOptions, setFieldOptions] = useState();
  // skillPageData?.map((item) => item?.name)
  // sub field  options
  const [subfieldOptions, setSubfieldOptions] = useState([]);
  // as per sub fiels , dev skills will change
  const [disableOptions, setDisableOptions] = useState(false);
  const [yearsOption, setYearsOption] = useState(
    Array(15)
      .fill()
      .map((_, index) => index + 1 + " year(s)")
      .concat("15+ year(s)")
  );
  const [viewLoader, setViewLoader] = useState(false);
  const localStorageKey = "candidateProfessionalDetails";
  const roleFieldOptionsKey = "roleFieldOptionsCache"; // Renamed key
  // to check error  fields
  const [errorFields, setErrorFields] = useState({
    frontend: false,
    backend: false,
    backendDatabase: false,
    fullStackFrontend: false,
    fullStackBackend: false,
    fullStackDatabase: false,
    dataScience: false,
    dataScienceDatabase: false,
  });

  // when there is change is field
  const handleFielOptionsChange = (value) => {
    if (value !== skillsForm?.skills?.field) {
      let temp = skillsForm;
      temp["skills"]["field"] = value;
      temp["skills"]["subfield"] = "";
      temp["skills"]["skillsdev"] = [];

      setSkillsForm({ ...temp });
      handleOptionsForSubfields(value);
    }
  };

  // set options for subfields
  // triggered when a field is changed
  const handleOptionsForSubfields = async (value) => {
    // -------- when values are stored in frontend ----------
    // let choosenField = [];
    // skillPageData?.forEach((item) => {
    //   if (item?.name === skillsForm?.skills?.field) {
    //     choosenField = item?.subfield;
    //   }
    // });
    // const temp = choosenField?.map((item) => item?.name);
    // setSubfieldOptions([...temp]);

    // -------- data fetching from api -------------------
    try {
      const endpoint = "/candidate/get/role/subfields";
      const data = {
        fieldName: value,
      };
      const res = await axios.post(endpoint, data);
      setSubfieldOptions([...res?.data]);
    } catch (err) {
      console.log("handleOptionsForSubfields error==>", err);
    }
  };

  // when there is chan in sub field
  const handleSubFieldOptionChange = (value) => {
    if (value !== skillsForm?.skills?.subfield) {
      let temp = skillsForm;
      temp["skills"]["subfield"] = value;
      temp["skills"]["skillsdev"] = [];
      setSkillsForm({ ...temp });
    }
  };

  const getProfessionalDetails = async () => {
    try {
      // First, check if the data is already in localStorage
      const storedData = localStorage.getItem(localStorageKey);

      if (storedData) {
        // If data is found in localStorage, use it directly
        const parsedData = JSON.parse(storedData);
        console.log("Using data from localStorage", parsedData);

        setSkillsForm({
          id: "",
          skills: {
            field: parsedData.field,
            subfield: parsedData.subfield,
            skillsdev: [...parsedData.skillsdev],
          },
        });

        if (parsedData.field) {
          handleOptionsForSubfields(parsedData.field);
        }
      } else {
        // If not found in localStorage, make the API call
        const endpoint = "/candidate/details/get/professional";
        const data = { id: localStorage.getItem("email") };
        const res = await axios.post(endpoint, data);

        if (res?.data) {
          console.log("getProfessionalDetails from API===>", res?.data);

          // Save the response data in localStorage
          localStorage.setItem(localStorageKey, JSON.stringify(res.data));

          // Use the API response data
          setSkillsForm({
            id: "",
            skills: {
              field: res?.data?.field,
              subfield: res?.data?.subfield,
              skillsdev: [...res?.data?.skillsdev],
            },
          });

          if (res?.data?.field) {
            handleOptionsForSubfields(res?.data?.field);
          }
        }
      }
    } catch (err) {
      console.error("error in getProfessionalDetails==>", err);
    }
  };

  const handleSave = async () => {
    if (!checkForMandatoryFields({ skillsForm, setErrorFields })) return;
    try {
      setViewLoader(true);
      const endpoint = "/candidate/details/save/professional";

      const data = skillsForm;
      data["id"] = localStorage.getItem("email");
      const res = await axios.post(endpoint, data);
      if (res) {
        localStorage.removeItem(localStorageKey);
        navigate("/assessment/home");
      }
    } catch (err) {
      console.error("error in handlesave==>", err);
    } finally {
      setViewLoader(false);
    }
  };

  const getPreAttemptedQuestions = async () => {
    try {
      const endpoint = "/candidate/round1/getConfig/preAttempted";
      const data = {
        // name: sessionStorage.getItem("email"),
        name: localStorage.getItem("email"),
      };
      const res = await axios.post(endpoint, data);
      if (res?.data?.questionsAttempted > 0) {
        setDisableOptions(true);
        // console.log("preAttempted==> ", res?.data?.questionsAttempted);
      }
    } catch (err) {
      console.log("preAttemptedQuestions error==>", err);
    }
  };

  const getFieldOptions = async () => {
    try {
      // Check if data is already in localStorage
      const storedData = localStorage.getItem(roleFieldOptionsKey);

      if (storedData) {
        // If data is found in localStorage, use it directly
        const parsedData = JSON.parse(storedData);
        console.log("Using field options from localStorage", parsedData);
        setFieldOptions([...parsedData]);
      } else {
        // If not found in localStorage, make the API call
        const endpoint = "/candidate/get/role/fields";
        const res = await axios.get(endpoint);

        if (res?.data) {
          console.log("Fetched field options from API", res.data);

          // Save the data in localStorage with the new key
          localStorage.setItem(roleFieldOptionsKey, JSON.stringify(res.data));

          // Set the field options state
          setFieldOptions([...res.data]);
        }
      }
    } catch (err) {
      console.log("getFieldOptions error==>", err);
    }
  };

  const getAllNEcessaryDetails = async () => {
    setViewLoader(true);
    await getProfessionalDetails();
    await getPreAttemptedQuestions();
    await getFieldOptions();
    setViewLoader(false);
  };

  const isButtonDisabled = () => {
    if (!skillsForm?.skills?.field) return true;
    if (!skillsForm?.skills?.subfield) return true;
    if (disableOptions) return true;
    return false;
  };

  useEffect(() => {
    // handleOptionsForSubfields();
    checkForMandatoryFields({ skillsForm, setErrorFields });
  }, [skillsForm]);

  useEffect(() => {
    getAllNEcessaryDetails();
  }, []);

  return (
    <>
      {viewLoader && <GlobalLoader />}
      <div className="skills-container">
        <div className="skills-items-content">
          <div className="skills-heading">
            {/* heading of the skill page */}
            <div className="title-values">
              <div>Category</div>
              <DropDownBox
                options={fieldOptions}
                value={skillsForm?.skills?.field}
                handleChange={handleFielOptionsChange}
                placeholder="Field"
                disableOptions={disableOptions}
              />
            </div>
            {skillsForm?.skills?.field && (
              <div className="title-values">
                <div>Role</div>
                <DropDownBox
                  options={subfieldOptions}
                  placeholder="Sub Field"
                  value={skillsForm?.skills?.subfield}
                  handleChange={handleSubFieldOptionChange}
                  disableOptions={disableOptions}
                />
              </div>
            )}
          </div>
          {skillsForm?.skills?.subfield === "Frontend" && (
            <FrontEndField
              disableOptions={disableOptions}
              setSkillsForm={setSkillsForm}
              skillsForm={skillsForm}
              skillPageData={skillPageData}
              yearsOption={yearsOption}
              errorFields={errorFields}
            />
          )}
          {skillsForm?.skills?.subfield === "Backend" && (
            <BackendField
              disableOptions={disableOptions}
              setSkillsForm={setSkillsForm}
              skillsForm={skillsForm}
              yearsOption={yearsOption}
              skillPageData={skillPageData}
              errorFields={errorFields}
            />
          )}
          {skillsForm?.skills?.subfield === "Full Stack" && (
            <FullStackField
              disableOptions={disableOptions}
              setSkillsForm={setSkillsForm}
              skillsForm={skillsForm}
              yearsOption={yearsOption}
              skillPageData={skillPageData}
              errorFields={errorFields}
            />
          )}
          {skillsForm?.skills?.subfield === "Data Science Engineer" && (
            <DataScience
              disableOptions={disableOptions}
              setSkillsForm={setSkillsForm}
              skillsForm={skillsForm}
              yearsOption={yearsOption}
              skillPageData={skillPageData}
              errorFields={errorFields}
            />
          )}
        </div>
        <div className="buttonsWrapper">
          <button onClick={() => navigate("/details/personal")}>
            Previous
          </button>
          <button
            disabled={isButtonDisabled()}
            style={{
              opacity: isButtonDisabled() ? 0.7 : 1,
              cursor: isButtonDisabled() ? "not-allowed" : "",
            }}
            title={
              disableOptions
                ? "Cannot Edit once test started"
                : isButtonDisabled()
                ? "Select Fields"
                : ""
            }
            onClick={() => handleSave()}
          >
            Save and Continue
          </button>
        </div>
      </div>
    </>
  );
};

export const DropDownBox = ({
  placeholder,
  options,
  value,
  handleChange,
  disableOptions,
}) => {
  const [viewOptions, setViewOptions] = useState(false);
  const componentRef = useRef(null);

  const dropDownChange = (item) => {
    setViewOptions(false);
    if (item !== value) handleChange(item);
  };

  const handleValue = () => {
    const possibleValuesToIgnore = ["", null, undefined];
    if (possibleValuesToIgnore.includes(value)) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        // Click outside the component
        setViewOptions(false);
      }
    };

    // Attach the event listener when the component mounts
    document.addEventListener("mousedown", handleClickOutside);

    // Detach the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    // Check if the element exists
    if (componentRef.current) {
      if (viewOptions) {
        // If viewOptions is true, add the 'clicked' class
        componentRef.current.classList.add("clickedItem");
      } else {
        // If viewOptions is false, remove the 'clicked' class
        componentRef.current.classList.remove("clickedItem");
      }
    }
  }, [viewOptions]);

  return (
    <div className="dropDown-wrapper" ref={componentRef}>
      <div
        className="placeholder"
        onClick={() => (disableOptions ? "" : setViewOptions(!viewOptions))}
        style={{ opacity: handleValue() ? "1" : "0.7" }}
      >
        <div className={handleValue() ? "value" : "no-value"}>
          {handleValue() ? value : placeholder}
        </div>
        <div>
          {!disableOptions && (
            <img
              src={arrowDown}
              alt=""
              style={{ rotate: viewOptions ? "180deg" : "0deg" }}
            />
          )}
        </div>
      </div>
      {viewOptions && (
        <div className="options-wrapper">
          {options?.map((item, index) => (
            <div key={index} onClick={() => dropDownChange(item)}>
              {item}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const checkForMandatoryFields = ({ skillsForm, setErrorFields }) => {
  // console.log("checkForMandatoryFields==.", skillsForm);
  // check for frontend
  if (skillsForm?.skills?.subfield === "Frontend") {
    let allItems = skillsForm?.skills?.skillsdev?.[0]?.languages
      ? skillsForm?.skills?.skillsdev?.[0]?.languages
      : [];

    // Check if any item in the array is checked and has a non-null language
    if (allItems.some((item) => item?.checked && item?.language !== null)) {
      // console.log("selected");
      return true;
    } else {
      setErrorFields({
        frontend: true,
        backend: false,
        backendDatabase: false,
        fullStackFrontend: false,
        fullStackBackend: false,
        fullStackDatabase: false,
      });
      return false;
    }
  }
  if (skillsForm?.skills?.subfield === "Backend") {
    let allItems = skillsForm?.skills?.skillsdev?.[0]?.languages
      ? skillsForm?.skills?.skillsdev?.[0]?.languages
      : [];

    let language = true;

    // Check if any item in the array is checked and has a non-null language
    if (allItems.some((item) => item?.checked && item?.language !== null)) {
      // console.log("selected");
      // return true;
      // language = true;
      language = false;
    } else {
      language = true;
    }

    let allItemsDatabase = skillsForm?.skills?.skillsdev?.[1]?.languages
      ? skillsForm?.skills?.skillsdev?.[1]?.languages
      : [];

    let database = true;

    // Check if any item in the array is checked and has a non-null language
    if (
      allItemsDatabase.some((item) => item?.checked && item?.language !== null)
    ) {
      database = false;
    } else {
      database = true;
    }

    setErrorFields({
      frontend: false,
      backend: language,
      backendDatabase: database,
      fullStackFrontend: false,
      fullStackBackend: false,
      fullStackDatabase: false,
    });

    if (language || database) {
      return false;
    } else {
      return true;
    }
  }
  if (skillsForm?.skills?.subfield === "Full Stack") {
    // return false;

    let allItems = skillsForm?.skills?.skillsdev?.[0]?.languages
      ? skillsForm?.skills?.skillsdev?.[0]?.languages
      : [];

    let frontEnd = true;
    // Check if any item in the array is checked and has a non-null language
    if (allItems.some((item) => item?.checked && item?.language !== null)) {
      // console.log("selected");
      // return true;
      frontEnd = false;
    } else {
      frontEnd = true;
    }

    let allItemsBackend = skillsForm?.skills?.skillsdev?.[1]?.languages
      ? skillsForm?.skills?.skillsdev?.[1]?.languages
      : [];

    let backEnd = true;
    // Check if any item in the array is checked and has a non-null language
    if (
      allItemsBackend.some((item) => item?.checked && item?.language !== null)
    ) {
      // console.log("selected");
      // return true;
      backEnd = false;
    } else {
      backEnd = true;
    }

    let allItemsDb = skillsForm?.skills?.skillsdev?.[2]?.languages
      ? skillsForm?.skills?.skillsdev?.[2]?.languages
      : [];

    let database = true;
    // Check if any item in the array is checked and has a non-null language
    if (allItemsDb.some((item) => item?.checked && item?.language !== null)) {
      // console.log("selected");
      // return true;
      database = false;
    } else {
      database = true;
    }

    setErrorFields({
      frontend: false,
      backend: false,
      backendDatabase: false,
      fullStackFrontend: frontEnd,
      fullStackBackend: backEnd,
      fullStackDatabase: database,
    });

    if (frontEnd || backEnd || database) {
      return false;
    } else {
      return true;
    }
  }

  if (skillsForm?.skills?.subfield === "Data Science Engineer") {
    let allItems = skillsForm?.skills?.skillsdev?.[0]?.languages
      ? skillsForm?.skills?.skillsdev?.[0]?.languages
      : [];

    let language = true;

    // Check if any item in the array is checked and has a non-null language
    if (allItems.some((item) => item?.checked && item?.language !== null)) {
      // console.log("selected");
      // return true;
      // language = true;
      language = false;
    } else {
      language = true;
    }

    let allItemsDatabase = skillsForm?.skills?.skillsdev?.[1]?.languages
      ? skillsForm?.skills?.skillsdev?.[1]?.languages
      : [];

    let database = true;

    // Check if any item in the array is checked and has a non-null language
    if (
      allItemsDatabase.some((item) => item?.checked && item?.language !== null)
    ) {
      database = false;
    } else {
      database = true;
    }

    setErrorFields({
      frontend: false,
      backend: false,
      backendDatabase: false,
      fullStackFrontend: false,
      fullStackBackend: false,
      fullStackDatabase: false,
      dataScience: language,
      dataScienceDatabase: database,
    });

    if (language || database) {
      return false;
    } else {
      return true;
    }
  }

  return false;
};
