import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { useInView } from "react-intersection-observer";
import styles from "./TopTechnologies.module.scss";

export const TopTechnologies = ({ data }) => {
  return (
    <Box
  className={styles.container}
  sx={{
    backgroundImage: `url(${data?.backgroundImageUrl || ""})`,
    backgroundSize: { lg: 'cover', sm: 'contain', xs: 'contain' }, // 'cover' for large screens, 'contain' for small screens
    backgroundPosition: 'center', // Keep the image centered
    backgroundRepeat: 'no-repeat', // Prevent repetition
    overflow: 'hidden', // Ensure content doesn't overflow the box bounds
    width: '100%', // Full width
    height:'100%',
    
    padding:{lg:"73px 0 64px 0",sm:"30px 0",xs:"20px 0",md:'30px 0'}
  }}
>
  <Typography
  className={styles.title}
  sx={{ fontSize: {lg:'34px',xs:'16px',sm:'16px'} }} // Inline MUI styling
>
  {data?.title}
</Typography>
     
     
<Grid container 
  sx={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  
    marginTop: { lg: '40px',sm:'24px',xs:'24px',md:'30px' },
    rowGap:{lg:'24px',sm:'8px',xs:'8px',md:'14px'}
  }}
  spacing={2}  // Add some space between the Grid items
>
  {data?.techFields?.map((tech, index) => (
    <TechField tech={tech} key={index} />
  ))}
</Grid>
    </Box>
  );
};

const TechField = ({ tech }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0,
  });

  return (
    <Grid  item sx={{display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',}} // Center the content within the box}}
      xs={6} sm={3} md={3} >
      <Box
        className={`${styles.techField} ${inView ? styles.animateIn : ""}`}
        ref={ref}
        sx={{
          background: tech?.gradient,
          width: { lg: '200px', sm: '150px', xs: '150px' },
          height: { lg: '60px', sm: '46px', xs: '46px' },
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center', // Center the content within the box
        }}
      >
        <Typography variant="h6" className={styles.techTitle}>
          {tech?.name || "Technology"}
        </Typography>
      </Box>
    </Grid>
  );
};