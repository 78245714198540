import React, { useState, useRef, useEffect } from 'react';
import { TextField, InputAdornment, Stack, Popper, Box, ClickAwayListener, Button, Typography, Chip } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile'; // Importing the upload icon
import image from "../../../Assets/client_tour/Searchbox.svg";
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CancelIcon from '@mui/icons-material/Cancel';


const ClientSearchBar = ({ serachItem ,handleJDResponse,handleSearchGetResponse,handleJDCancelled}) => {
  const [open, setOpen] = useState(false); // State to control Popper open/close
  const anchorRef = useRef(null); // Reference for positioning the Popper
  const [fileName,setFileName]=useState("")
  const [searchScript,setSearchScript]=useState("")
  const [error,setError]=useState(false);
  const [errorMessage,setErrorMessage]=useState("");

  useEffect(()=>{
    if(serachItem){
      console.log(serachItem,"serachItemserachItem")
      setSearchScript(serachItem);
    }
  },[serachItem])

  const handleIconClick = () => {
    setOpen((prev) => !prev); // Toggle popper visibility
  };

  const handleClosePopper = () => {
    setOpen(false); // Close popper
  };

  const handleFileName=(fileName1,formData)=>{
    setError(false)
   handleJDResponse(formData);
    setFileName(fileName1);
    handleClosePopper();

  }

  const handleFileUploadError=(message)=>{
    console.log("called",message)
    setError(true);
    setErrorMessage(message)
    handleClosePopper();
    setFileName("");

  }

  const handleDelete=()=>{
    setFileName('')
    handleJDCancelled()
  }

  const handleSearch=()=>{
    handleSearchGetResponse(searchScript)
  }
  

  return (
    <Stack sx={{ width: '682px', height: '50px' }} ref={anchorRef}>
<TextField
  value={searchScript}
  onChange={(e) => {
    setSearchScript(e.target.value);
  }}
  variant="outlined"
  fullWidth
  placeholder="Tell us about your talent needs"
  InputProps={{
    startAdornment: (
      <InputAdornment
        position="start"
        sx={{ paddingLeft: '20px', color: 'rgba(33, 33, 33, 1)', cursor: 'pointer' }}
        onClick={handleIconClick}
      >
        <AttachFileIcon />
      </InputAdornment>
    ),
    endAdornment: (
      <InputAdornment position="end">
        <img
          style={{
            height: '50px',
            width: 'auto',
            margin: 0,
            padding: 0,
            cursor: 'pointer',
          }}
          src={image}
          alt="search icon"
          onClick={handleSearch}
        />
      </InputAdornment>
    ),
    sx: {
      backgroundColor: '#fff',
      borderRadius: '10px',
      padding: '0px',
      height: '50px',
      boxShadow: '0px 0px 10px rgba(33, 33, 33, 0.12)',
      '.MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '& input': {
        '&:-webkit-autofill': {
          WebkitBoxShadow: '0 0 0 1000px white inset',
          WebkitTextFillColor: '#000',
          transition: 'background-color 5000s ease-in-out 0s',
        },
        width: '100%', // Ensures the input width stays consistent
        boxSizing: 'border-box', // Ensures padding doesn't affect width
      },
    },
  }}
  inputProps={{
    style: {
      width: '100%', // Maintain consistent width
      padding: '0 10px', // Consistent padding
      boxSizing: 'border-box',
    },
  }}
/>



{fileName && (
  <Chip
  label={fileName.length > 10 ? `${fileName.substring(0, 10)}...` : fileName}
  onDelete={handleDelete} // Called when the cancel icon is clicked
  deleteIcon={<CancelIcon />} // Custom delete icon (Cancel)
  sx={{
    mt: '10px', // Margin top of 4px
    width: '154px', // Fixed width
    height: '28px',
    backgroundColor: '#fff',
    borderRadius: '4px', // Adjust border radius
    '& .MuiChip-label': {
      // display: 'flex',
      // alignItems: 'center',
      flexDirection: 'row-reverse', // Reverse the row direction to place the icon first
    },
    '& .MuiChip-deleteIcon': {
      marginRight: '4px', // Add spacing between the icon and the text
      width: '18px', // Set width of delete icon
      height: '18px', // Set height of delete icon
    },
  }}

  
/>


)}



{error && (
  <Chip
  label={errorMessage.length > 400 ? `${errorMessage.substring(0, 10)}...` : errorMessage}
  sx={{
    mt: '10px', // Margin top
    width: '260px', // Fixed width
    height: '28px',
    backgroundColor: '#fff', // Background color
    borderRadius: '4px', // Border radius
    color: 'rgba(229, 38, 28, 1)', // Custom red color for label
    '& .MuiChip-label': {
      display: 'flex',
      alignItems: 'center',
      color: 'rgba(229, 38, 28, 1)', // Custom red color for label
      fontWeight: 'bold',
    },
  }}
  icon={
    <ErrorOutlineOutlinedIcon
      sx={{
        color: 'rgba(229, 38, 28, 1) !important', // Force custom red color
        marginRight: '8px',
      }}
    />
  }
/>

  



)}

      {/* Popper for uploading files */}
      <UploadPopper open={open} anchorEl={anchorRef.current} onClose={handleClosePopper}handleFileUploadError={handleFileUploadError} handleFileName={handleFileName} />
    </Stack>
  );
};

// Popper Component
const UploadPopper = ({ open, anchorEl, onClose,handleFileName ,handleFileUploadError}) => {
  const styles = {
    width: '280px', // Slightly larger to create padding for the inner box
    height: '197px', // Slightly larger to create padding for the inner box
    padding: '10px', // Padding to simulate the gap between dashed lines
    borderRadius: '10px',
    border: '1px dashed rgba(8, 55, 141, 1)', // Outer dashed border
    display: 'flex',
    flexDirection:'column',
    justifyContent: 'center',
    alignItems: 'center', // Center the inner box
    backgroundColor: '#fff', // Background color for the outer box (if needed)
    boxShadow: '0px 0px 10px rgba(33, 33, 33, 0.2)', // Box shadow for the popper
  };
  

  return (
    <Popper open={open} anchorEl={anchorEl} placement="bottom-start" style={{ zIndex: 1300 }}>
      <ClickAwayListener onClickAway={onClose}>
        <Box sx={styles}>
          <UploadIcon />
         <UploadButton handleFileName={handleFileName} handleFileUploadError={handleFileUploadError}/>
          
        </Box>
      </ClickAwayListener>
    </Popper>
  );
};

const UploadIcon = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 40, // Adjust size as needed
        height: 40, // Adjust size as needed
        borderRadius: '50%',
        background: 'linear-gradient(135deg, rgba(11, 82, 212, 1), rgba(6, 43, 110, 1))',
        marginBottom:'12px'
      }}
    >
      <FileUploadOutlinedIcon sx={{ fontSize: 40, color: '#fff',width:'40px',height:'40px' }} />
    </Box>
  );
};


const UploadButton = ({handleFileName,handleFileUploadError}) => {
  const fileInputRef = useRef(null);

  // Function to handle button click and trigger file input
  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Trigger the file input
    }
  };

  // Function to handle file selection
  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Get the first selected file
    const isFileSizeValid = file.size <= 5 * 1024 * 1024; // 5MB in bytes

    // Check file type (should be PDF)
    if(!isFileSizeValid){
      handleFileUploadError("File size should be less than 5 mb")
      return;
    }
   
    if (file && file.type === 'application/pdf' ) {
      // Only process if the file is a PDF
      const formData = new FormData();
      formData.append('file', file,); // Append file with its name to FormData
      formData.append("message","provide me the list of developers matching the job description")
      formData.append("client_id",localStorage.getItem("email"))
      handleFileName(file.name,formData);
      
    } else {
      handleFileUploadError("Upload only pdf files")
    }
  };
  return (
    <>
    <Box
      sx={{
        position: 'relative',
        display: 'inline-block',
        borderRadius: '10px', // Border-radius for the entire element
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          borderRadius: 'inherit',
          padding: '2px', // Adjust the border thickness
          background: 'linear-gradient(135deg, rgba(11, 82, 212, 1), rgba(6, 43, 110, 1))',
          '-webkit-mask':
            'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
          mask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
          maskComposite: 'exclude',
        },
      }}
    >
      <Button
        sx={{
          width: '180px',
          padding: '10px',
          textTransform: 'none',
          borderRadius: '10px', // Apply the same border-radius here
          background: 'transparent', // Transparent background
          color: 'transparent',
          backgroundClip: 'text',
          backgroundImage:
            'linear-gradient(135deg, rgba(6, 43, 110, 1), rgba(11, 82, 212, 1), rgba(72, 89, 243, 1))', // Gradient text
          display: 'flex',
          justifyContent: 'center',
          fontSize: '16px',
          fontWeight: 'bold',
          position: 'relative',
          zIndex: 1, // Keep it above the pseudo-element
          '&:hover': {
            backgroundColor: 'transparent', // Maintain transparency on hover
          },
        }}
        variant="outlined"
        onClick={handleButtonClick}
      >
        Upload your JD
      </Button>
      <input
        ref={fileInputRef}
        type="file"
        accept="application/pdf" // Restrict to only PDF files
        style={{ display: 'none' }}
        onChange={handleFileChange} // Trigger when a file is selected
      />
    </Box>
    <Typography
      sx={{
        marginTop:'5px',
        fontFamily: 'Poppins',
        fontSize: '11px',
        fontWeight: 400,
        lineHeight: '16.5px',
        textAlign: 'left',
        color: 'rgba(146, 146, 146, 1)',
      }}
    >
     Upload Pdf file of max 5 MB only.
    </Typography>
     </>
  );
};

export default ClientSearchBar;
