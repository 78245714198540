import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Dropdown } from "../../../Assets/SVG/Dropdown.svg";
import "./Header.scss";
import Menu_Open from "../../../Assets/home/menu_open.svg";
import Menu_Close from "../../../Assets/home/menu_close.svg";

export function Header() {
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);

  const handleMouseEnter = () => {
    setShowDropdown(true);
  };

  const handleMouseLeave = () => {
    setShowDropdown(false);
  };

  return (
    <>
      <div className="headerMain">
        <div className="header-container">
          <div className="header-sub">
            <div className="logo" onClick={() => navigate("/")}>
              <img src="/images/zeero-logo.svg" alt="logo" />
            </div>
            <div
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              className="services-dropdown"
            >
              Services
              <span>
                <Dropdown />
              </span>
              {showDropdown && (
                <div className="main-dropDown">
                  <div className="dropdown">
                    <div onClick={() => navigate("/client")}>Clients</div>
                    <div onClick={() => navigate("/developer")}>Developers</div>
                  </div>
                </div>
              )}
            </div>
            {/* <div>About Us</div> */}
            <div
              className="contact-item"
              onClick={() => navigate("/contact/all")}
            >
              Contact Us
            </div>
            <div className="menu-logo" onClick={() => setShowMenu(!showMenu)}>
              <img src={showMenu ? Menu_Open : Menu_Close} alt="" />
            </div>
          </div>
          <div className="header-sub2">
            <button  onClick={() => navigate("/login")}>
              Join as Developer
            </button>
            {/* <button onClick={() => navigate("/ClientLogin")}>Hire Talent</button> */}
            <button   onClick={() => navigate("/signup-client")}>
              Hire Talent
            </button>
          </div>
        </div>
      </div>
      {showMenu && <MobileMenuItems />}
    </>
  );
}

const MobileMenuItems = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Disable scrolling on the body
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  return (
    <div className="mobile-menu-container">
      <div className="services-dropdown">
        <div className="titles" onClick={() => setShowDropdown(!showDropdown)}>
          <div
            style={{
              borderBottom: showDropdown ? "2px solid #0b52d4" : "",
              padding: "0px 2px",
            }}
          >
            Services
          </div>
          <div style={{ rotate: showDropdown ? "180deg" : "0deg" }}>
            <Dropdown />
          </div>
        </div>
        {showDropdown && (
          <div className="main-dropDown">
            <div className="dropdown">
              <div onClick={() => navigate("/client")}>Clients</div>
              <div onClick={() => navigate("/developer")}>Developers</div>
            </div>
          </div>
        )}
      </div>
      <div className="contact-item" onClick={() => navigate("/contact/all")}>
        Contact Us
      </div>
      {/* <div className="header-sub2">
        <button onClick={() => navigate("/login")}>Join as Developer</button>
        <button onClick={() => navigate("/contact/client")}>Hire Talent</button>
      </div> */}
    </div>
  );
};